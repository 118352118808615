@import url(https://fonts.googleapis.com/css?family=Heebo:300,400,700);
html, body, #root, .app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Heebo", sans-serif;
  font-weight: 400; }

.navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 4px solid #1c99ff;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  transition: 0.3s; }
  @media screen and (max-width: 700px) {
    .navbar--open {
      height: 100%; } }

.navbar__logo {
  height: 40px;
  margin: 10px;
  margin-left: 16px;
  fill: #949494; }
  .navbar__logo:hover {
    cursor: pointer; }
  @media screen and (max-width: 700px) {
    .navbar__logo {
      display: none; } }

.navbar__navlinks {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex; }
  @media screen and (max-width: 700px) {
    .navbar__navlinks {
      width: 100%;
      flex-direction: column;
      align-items: center;
      overflow: hidden; } }

.navbar__navlink {
  width: 120px;
  text-align: center;
  line-height: 60px;
  color: #949494;
  font-weight: 700;
  text-decoration: none; }
  .navbar__navlink:hover {
    background-color: #82c7ff;
    cursor: pointer; }
  .navbar__navlink--selected {
    color: #4d4d4d;
    background-color: #1c99ff; }
  @media screen and (max-width: 700px) {
    .navbar__navlink {
      width: 100%; } }

.navbar__toggle-icon {
  display: none; }
  .navbar__toggle-icon:hover {
    cursor: pointer; }
  @media screen and (max-width: 700px) {
    .navbar__toggle-icon {
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;
      font-size: 36px;
      display: block; } }

.social-card {
  width: 300px;
  height: 80px;
  margin: 20px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  text-decoration: none; }
  .social-card:hover {
    cursor: pointer; }
  @media screen and (max-width: 500px) {
    .social-card {
      width: 90%; } }
  @media screen and (max-height: 700px) {
    .social-card {
      margin: 5px; } }

.social-card__icon {
  width: 120px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 48px;
  color: #949494; }

.social-card__text {
  font-size: 36px;
  text-align: center;
  color: #949494; }

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(242, 156, 36, 0.4); }
  @media screen and (max-width: 700px) {
    .footer {
      display: grid;
      grid-template-columns: 50% 50%; } }

.footer__entry {
  height: 50px;
  width: 160px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  text-decoration: none;
  color: #4d4d4d; }
  @media screen and (max-width: 700px) {
    .footer__entry {
      width: 100%; } }

@media screen and (max-width: 700px) {
  .footer__copyright {
    order: 1; } }

.home-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://imgur.com/3TwMPI7.png");
  background-size: cover; }

.home-view__center-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.home-view__social {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-height: 600px) and (orientation: landscape) {
    .home-view__social {
      flex-direction: row; } }

.home-view__logo {
  max-width: 700px; }
  @media screen and (max-width: 800px) {
    .home-view__logo {
      max-width: 90%; } }

.about {
  width: 100%;
  padding: 50px;
  padding-top: 80px; }

.about h1 {
  color: #4d4d4d;
  font-weight: 300; }

.not-found-view {
  padding: 70px;
  padding-top: 100px;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  @media screen and (max-width: 700px) {
    .not-found-view {
      flex-direction: column;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px; } }

.not-found-view__icon {
  font-size: 200px;
  padding-right: 30px; }
  @media screen and (max-width: 700px) {
    .not-found-view__icon {
      padding: 0;
      padding-bottom: 30px; } }

.not-found-view__text {
  font-size: 50px; }
  @media screen and (max-width: 700px) {
    .not-found-view__text {
      font-size: 30px; } }

