.not-found-view {
  padding: 70px;
  padding-top: 100px;
  color: $color-text;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media screen and (max-width: $break-mobile) {
    flex-direction: column;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.not-found-view__icon {
  font-size: 200px;
  padding-right: 30px;
  @media screen and (max-width: $break-mobile) {
    padding: 0;
    padding-bottom: 30px;
  }
}

.not-found-view__text {
  font-size: 50px;
  @media screen and (max-width: $break-mobile) {
    font-size: 30px;
  }
}