.home-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://imgur.com/3TwMPI7.png");
  background-size: cover;
}

.home-view__center-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-view__social {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 600px) and (orientation: landscape) {
    flex-direction: row;
  }
}

.home-view__logo {
  max-width: 700px;
  @media screen and (max-width: 800px) {
    max-width: 90%;
  }
}