.about {
  width: 100%;
  padding: 50px;
  padding-top: 80px;
}

.about h1 {
  color: $color-text;
  font-weight: 300;
}
