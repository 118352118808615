.navbar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: $color-background;
  border-bottom: 4px solid $color-primary;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  transition: 0.3s;
  @media screen and (max-width: $break-mobile) {
    &--open {
      height: 100%;
    }
  }
}

.navbar__logo {
  height: 40px;
  margin: 10px;
  margin-left: 16px;
  fill: $color-text-light;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: $break-mobile) {
    display: none;
  }
}

.navbar__navlinks {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  @media screen and (max-width: $break-mobile) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
}

.navbar__navlink {
  width: 120px;
  text-align: center;
  line-height: 60px;
  color: $color-text-light;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    background-color: $color-primary-light;
    cursor: pointer;
  }
  &--selected {
    color: $color-text;
    background-color: $color-primary;
  }
  @media screen and (max-width: $break-mobile) {
    width: 100%;
  }
}

.navbar__toggle-icon {
  display: none;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: $break-mobile) {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 36px; 
    display: block;
  }
}
